import { ReactElement, useEffect, useState } from 'react';
import styles from './LokoTVPage.module.scss';
import VideoModal from '../VideoModal/VideoModal';
import { VKVideosVideo, VKVideosVideoImage } from '../../types/Types';
import { ReactComponent as Arrow } from '../../assets/upper_right_arrow.svg';
import SideMenu from '../common/SideMenu/SideMenu';
import Container from '../common/Container/Container';
import BreadcrumbsWrapper from 'components/common/BreadcrumbsWrapper/BreadcrumbsWrapper';
import PageHeader from 'components/common/PageHeader/PageHeader';
import ImageBgPageWrapper from '../common/ImageBgPageWrapper/ImageBgPageWrapper';
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton';
import { getVkVideos } from 'api/api';
import Url from 'constants/urls';
import { useTranslation } from 'react-i18next';
import Colors from 'constants/colors';
import Spinner from 'components/Spinner/Spinner';
import ErrorWidget from 'components/common/ErrorWidget/ErrorWidget';
import { intlFormatDistance } from 'date-fns';
import ViewMoreButton from 'components/ViewMoreButton/ViewMoreButton';
import YearVideoFilter from './YearVideoFilter/YearVideoFilter';

const PAGE_SIZE = 24;

const formatViews = (
  count: number
): {
  value: number;
  multiplier: string;
} => {
  if (count >= 1_000_000_000) {
    return {
      value: Number((count / 1_000_000_000).toFixed(1)),
      multiplier: 'billions',
    };
  }
  if (count >= 1_000_000) {
    return {
      value: Number((count / 1_000_000).toFixed(1)),
      multiplier: 'millions',
    };
  }
  if (count >= 1_000) {
    return {
      value: Number((count / 1_000).toFixed(1)),
      multiplier: 'thousands',
    };
  }
  return { value: count, multiplier: 'units' };
};

const LokoTvPage = (): ReactElement => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState<VKVideosVideo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [yearSelected, setYearSelected] = useState<number>();

  const [selectedVideo, setSelectedVideo] = useState<VKVideosVideo>();
  const [total, setTotal] = useState<number>(0);

  const isEnd = videos.length >= total;
  console.log(videos.length, total);

  useEffect(() => {
    const getVideos = async () => {
      try {
        const vkVideo = await getVkVideos(0, PAGE_SIZE + 1, yearSelected);

        const videoData = vkVideo.data.map((entry) => entry.attributes.json);
        setVideos(videoData);
        setTotal(
          (vkVideo.meta.pagination as { total: number }).total as number
        );
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getVideos();
  }, [yearSelected]);

  const loadMoreVideos = async () => {
    const vkVideo = await getVkVideos(videos.length, PAGE_SIZE, yearSelected);

    const videoData = vkVideo.data.map((entry) => entry.attributes.json);
    setVideos([...videos, ...videoData]);
    setTotal(
      (vkVideo.meta.pagination as { total: number }).total as number
    );
  };

  const onClose = () => {
    setSelectedVideo(undefined);
  };

  const renderContent = () => {
    if (isLoading) return <Spinner />;
    if (isError) return <ErrorWidget.Error />;

    return (
      <>
        <YearVideoFilter
          value={yearSelected}
          onSelect={(year) => setYearSelected(year)}
        />
        <div>
          {videos && videos.length > 0 && (
            <div
              className={styles.mainVideo}
              onClick={() => setSelectedVideo(videos[0])}
            >
              <img
                className={styles.previewImageMainVideo}
                src={
                  videos[0].image.reduce(
                    (
                      result: VKVideosVideoImage,
                      image: VKVideosVideoImage,
                      index: number,
                      array: VKVideosVideoImage[]
                    ) => {
                      if (image.width > 0 || image.height > 0) return image;

                      return result;
                    },
                    {
                      url: '',
                      width: 0,
                      height: 0,
                    }
                  ).url
                }
                alt={videos[0].title}
              />
              <div className={styles.descriptionMainVideo}>
                <div className={styles.title}>{videos[0].title}</div>
                <div className={styles.date}>{videos[0].description}</div>
                <div className={styles.date}>
                  {t(
                    `lokoTvPage.views.${
                      formatViews(videos[0].views).multiplier
                    }`,
                    { count: formatViews(videos[0].views).value }
                  )}
                  {' · '}
                  {intlFormatDistance(
                    new Date(videos[0].date * 1000),
                    new Date(),
                    { locale: 'ru' }
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={styles.videoList}>
          {videos &&
            videos.length > 0 &&
            videos.map(
              (video, index) =>
                video !== videos[0] && (
                  <div
                    key={`${video.id}_${index}`}
                    className={styles.video}
                    onClick={() => setSelectedVideo(video)}
                  >
                    <img
                      className={styles.previewImage}
                      src={
                        video.image.reduce(
                          (
                            result: VKVideosVideoImage,
                            image: VKVideosVideoImage,
                            index: number,
                            array: VKVideosVideoImage[]
                          ) => {
                            if (image.width > 0 || image.height > 0)
                              return image;

                            return result;
                          },
                          {
                            url: '',
                            width: 0,
                            height: 0,
                          }
                        ).url
                      }
                      alt={video.title}
                    />
                    <div className={styles.description}>
                      <div className={styles.title}>{video.title}</div>
                      <div className={styles.date}>{video.description}</div>
                      <div className={styles.date}>
                        {t(
                          `lokoTvPage.views.${
                            formatViews(video.views).multiplier
                          }`,
                          { count: formatViews(video.views).value }
                        )}
                        {' · '}
                        {intlFormatDistance(
                          new Date(video.date * 1000),
                          new Date(),
                          { locale: 'ru', style: 'short' }
                        )}
                      </div>
                    </div>
                  </div>
                )
            )}
        </div>
        <div className={styles.ScrollToTopButtonWrapper}>
          <ScrollToTopButton />
        </div>
        {!isEnd ? (
          <div className={styles.buttonWrapper}>
            <ViewMoreButton onClick={loadMoreVideos} />
          </div>
        ) : (
          <div className={styles.buttonWrapper}>
            <a
              className={styles.youtubeLinkButton}
              href={Url.socials.VK_VIDEO}
              target='_blank'
              rel='noreferrer'
            >
              <div className={styles.text}>
                {t('lokoTvPage.navigateToVKVideo')}
              </div>
              <Arrow color={Colors.PRIMARY_RED} />
            </a>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <ImageBgPageWrapper>
        <Container paddingTop='50px'>
          <BreadcrumbsWrapper
            breadcrumbs={[
              {
                name: t('lokoTvPage.breadcrumb'),
                url: '/lokotv',
              },
            ]}
          />
          <div className={styles.contentWrapper}>
            <PageHeader text={t('lokoTvPage.header')} />
          </div>
          <div className={styles.wrapper}>
            <div className={styles.content}>{renderContent()}</div>
            <div className={styles.sideMenu}>
              <SideMenu />
            </div>
          </div>
        </Container>
      </ImageBgPageWrapper>
      <VideoModal
        isVisible={!!selectedVideo}
        videoSource={selectedVideo?.player ?? ''}
        close={onClose}
        isVKVideo
      />
    </>
  );
};

export default LokoTvPage;
