import styles from './VideoModal.module.scss';
import { Modal } from 'antd';
import YouTube from 'react-youtube';

interface VideoModalProps {
  isVisible: boolean;
  videoSource: string;
  isYoutube?: boolean;
  isVKVideo?: boolean;
  close: () => void;
}

const VideoModal = ({
  isVisible,
  videoSource,
  isYoutube,
  isVKVideo,
  close,
}: VideoModalProps) => {
  const renderVideo = () => {
    return (
      <video className={styles.video} controls controlsList='nodownload'>
        <source src={videoSource} />
      </video>
    );
  };

  const renderYoutubeVideo = () => {
    return (
      <YouTube
        key={videoSource}
        className={styles.player}
        videoId={videoSource}
        opts={{ height: '100%', width: '100%' }}
      />
    );
  };

  const renderVKVideo = () => {
    return (
      <iframe
        title='VK video player'
        src={videoSource}
        allow='encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
        allowFullScreen
        style={{ width: '100%', height: '100%' }}
      ></iframe>
    );
  };

  return (
    <Modal
      open={isVisible}
      onCancel={close}
      closable={false}
      className={styles.modal}
      footer={null}
      centered
      destroyOnClose={true}
    >
      <div className={styles.modalContent}>
        {isVKVideo
          ? renderVKVideo()
          : isYoutube
          ? renderYoutubeVideo()
          : renderVideo()}
      </div>
    </Modal>
  );
};

export default VideoModal;
