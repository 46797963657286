import styles from './VideoList.module.scss';
import { ReactElement, useEffect, useState } from 'react';
import playIcon from 'assets/video-play-icon.svg';
import Spinner from '../../../Spinner/Spinner';
import ErrorWidget from '../../../common/ErrorWidget/ErrorWidget';
import { getVkVideos } from 'api/api';
import { VKVideosVideo, VKVideosVideoImage } from 'types/Types';

type VideoListProps = {
  selectVideo: (id: string) => void;
  selectedVideo: string | undefined;
};

function VideoList({
  selectVideo,
  selectedVideo,
}: VideoListProps): ReactElement {
  const [videos, setVideos] = useState<VKVideosVideo[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const getVideos = async () => {
      try {
        const vkVideo = await getVkVideos();
        const videoData = vkVideo.data.map((entry) => {
          return entry.attributes.json;
        });
        setVideos(videoData);
        selectVideo(videoData[0].player);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getVideos();
  }, [selectVideo]);

  const renderDescription = (video: VKVideosVideo) => {
    switch (video.live_status) {
      case 'started':
        return (
          <>
            <img src={playIcon} alt='' width={10} height={13} />
            <div className={styles.description}>Смотреть трансляцию</div>
          </>
        );
      case 'waiting':
        return (
          <div className={styles.description}>Трансляция скоро начнется</div>
        );
      case 'upcoming':
        return (
          <div className={styles.description}>Трансляция скоро начнется</div>
        );
      default:
        return <div className={styles.description}>{video.description}</div>;
    }
  };

  const renderVideoList = () => {
    return videos.reduce((result: any, video, index, array) => {
      result.push(
        <div
          key={video.id}
          className={styles.video}
          onClick={() => selectVideo(video.player)}
        >
          <div className={styles.imgWrapper}>
            <img
              className={styles.previewImage}
              src={
                video.image.reduce(
                  (
                    result: VKVideosVideoImage,
                    image: VKVideosVideoImage,
                    index: number,
                    array: VKVideosVideoImage[]
                  ) => {
                    if (image.width > 0 || image.height > 0) return image;

                    return result;
                  },
                  {
                    url: '',
                    width: 0,
                    height: 0,
                  }
                ).url
              }
              alt={video.title}
            />
          </div>
          <div className={styles.descriptionBlock}>
            <h2 className={styles.title}>{video.title}</h2>
            <div className={styles.descriptionWrapper}>
              {renderDescription(video)}
            </div>
          </div>
        </div>
      );

      if (index !== array.length - 1) {
        result.push(
          <div className={styles.separator} key={`separator-${index}`} />
        );
      }

      return result;
    }, []);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (isError) {
      return <ErrorWidget.Error />;
    }
    return renderVideoList();
  };

  return (
    <div className={styles.videoListWrapper}>
      <div id={'loco-tv-video-list'} className={styles.videoList}>
        {renderContent()}
      </div>
    </div>
  );
}

export default VideoList;
