import classNames from 'classnames';
import { ReactElement, useEffect, useState } from 'react';
import {
  CommonVideo,
  DefaultImageObject,
  ExecutiveDto,
  File,
  PlayerInfoType,
  VKVideosVideo,
  VKVideosVideoImage,
} from '../../types/Types';
import ExecutiveStatisticTable from '../ExecutiveStatisticTable/ExecutiveStatisticTable';
import styles from './CoachTabsWithInfo.module.scss';
import PlayerPhoto from '../PlayerPage/PlayerPhoto/PlayerPhoto';
import ShowMoreText from '../ShowMoreText/ShowMoreText';
import { useTranslation } from 'react-i18next';
import ViewMoreButton from 'components/ViewMoreButton/ViewMoreButton';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from 'constants/adaptivity';
import PlayerVideo from 'components/PlayerPage/PlayerVideo/PlayerVideo';
import { getDefaultPlayerImage, getVideos, getVkVideosByTag } from 'api/api';
import VideoModal from 'components/VideoModal/VideoModal';
import { DEFAULT_IMAGE_NAME } from 'constants/constants';
import Spinner from 'components/Spinner/Spinner';

type CoachTabsWithInfoProps = {
  coach: ExecutiveDto;
  images: File[];
  openAlbum: (startIndex?: number) => void;
  getPhotos: (limit?: number) => void;
  currentPhotosFetch: File[];
  isPhotosLoading: boolean;
};

function CoachTabsWithInfo({
  coach,
  images,
  openAlbum,
  getPhotos,
  currentPhotosFetch,
  isPhotosLoading,
}: CoachTabsWithInfoProps): ReactElement {
  const { t } = useTranslation();
  const isSmallDesktop = useMediaQuery({
    maxWidth: Breakpoints.smallDesktop,
  });
  const ALBUM_PAGE_AMOUNT = isSmallDesktop ? 8 : 9;
  const NEXT_ALBUM_PAGE = 18; // для следующего запроса
  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeMediaTab, setActiveMediaTab] = useState(0);
  const { attributes } = coach;
  const [topPhotos, setTopPhotos] = useState<File[]>([]);
  const [pageCount, setPageCount] = useState<number>(NEXT_ALBUM_PAGE);
  const [isVisibleShowMoreBtn, setIsVisibleShowMoreBtn] =
    useState<boolean>(true);
  const [defaultPhoto, setDefaultPhoto] = useState<DefaultImageObject>();
  const [videoCount, setVideoCount] = useState<number>(ALBUM_PAGE_AMOUNT);
  const [strapiVideos, setStrapiVideos] = useState<File[]>([]);
  const [vkVideos, setVkVideos] = useState<
    {
      id: number;
      json: VKVideosVideo;
      web_date: string;
      web_id: string;
    }[]
  >([]);
  const [videosToDisplay, setVideosToDisplay] = useState<
    { isVkVideo: boolean; videoData: CommonVideo }[]
  >([]);
  const [selectedVideo, setSelectedVideo] = useState<
    { isVkVideo: boolean; source: string } | undefined
  >();
  const [isLoadingStrapiVideos, setIsLoadingStrapiVideos] = useState(false);
  const [isLoadingVkVideos, setIsLoadingVkVideos] = useState(false);

  useEffect(() => {
    if (images.length > 0 && images.length < ALBUM_PAGE_AMOUNT) {
      setIsVisibleShowMoreBtn(false);
    }
  }, [currentPhotosFetch, images, topPhotos.length]);

  useEffect(() => {
    const getData = async () => {
      try {
        const photo = await getDefaultPlayerImage(DEFAULT_IMAGE_NAME);
        setDefaultPhoto(photo.data[0]);
      } catch (e: any) {
        console.error(e);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getStrapiVideos = async () => {
      try {
        if (coach) {
          setIsLoadingStrapiVideos(true);
          const videos = await getVideos(
            `${coach?.attributes?.name} ${coach?.attributes?.surname}`,
            -1
          );
          setStrapiVideos(videos);
        }
      } catch (error: any) {
        console.error(error?.response?.data?.error || error?.message);
      } finally {
        setIsLoadingStrapiVideos(false);
      }
    };
    getStrapiVideos();
  }, [coach]);

  useEffect(() => {
    if (activeMediaTab === 1) {
      getVkVideos();
    }
  }, [coach, activeMediaTab]);

  useEffect(() => {
    const topStrapiVideos = strapiVideos.slice(0, videoCount).map((video) => {
      return {
        isVkVideo: false,
        videoData: {
          id: video.id.toString(),
          url: video.url,
          title: video.name,
          date: video.createdAt,
          previewUrl: video.previewUrl,
        },
      };
    });
    let topVideos: { isVkVideo: boolean; videoData: CommonVideo }[] = [];

    if (topStrapiVideos.length < videoCount) {
      topVideos = vkVideos
        .slice(0, videoCount - topStrapiVideos.length)
        .map((video) => {
          return {
            isVkVideo: true,
            videoData: {
              id: video.web_id,
              url: video.json.player,
              title: video.json.title
                .replace(/&quot;|&#039;/g, '"')
                .replace(/&#39;/g, "'"),
              date: new Date(Number(video.web_date) * 1000).toISOString(),
              previewUrl: video.json.image.reduce(
                (
                  result: VKVideosVideoImage,
                  image: VKVideosVideoImage,
                  index: number,
                  array: VKVideosVideoImage[]
                ) => {
                  if (image.width > 0 || image.height > 0) return image;

                  return result;
                },
                {
                  url: '',
                  width: 0,
                  height: 0,
                }
              ).url,
            },
          };
        });
    }

    setVideosToDisplay([...topStrapiVideos, ...topVideos]);
  }, [videoCount, strapiVideos, vkVideos]);

  useEffect(() => {
    if (activeMediaTab === 1) {
      if (videosToDisplay.length < videoCount) {
        getVkVideos();
      }
    }
  }, [activeMediaTab, videosToDisplay]);

  const getVkVideos = async () => {
    if (vkVideos.length) {
      return;
    }

    try {
      if (!vkVideos.length) {
        setIsLoadingVkVideos(true);
      }

      const result = await getVkVideosByTag(coach?.attributes?.surname);

      setVkVideos(result);
    } catch (error: any) {
      console.error(error?.response?.data?.error || error?.message);
    } finally {
      setIsLoadingVkVideos(false);
    }
  };

  const onVideoSelect = (source: string, isVkVideo: boolean) => {
    setSelectedVideo({
      source,
      isVkVideo,
    });
  };

  const onClose = () => {
    setSelectedVideo(undefined);
  };

  function onLoadMore(param: string) {
    if (param === PlayerInfoType.PHOTOS) {
      getPhotos(pageCount);
      setTopPhotos(currentPhotosFetch);
      setPageCount(pageCount + ALBUM_PAGE_AMOUNT);
      if (
        images.length > 0 &&
        (images.length === topPhotos.length ||
          images.length < ALBUM_PAGE_AMOUNT)
      ) {
        setIsVisibleShowMoreBtn(false);
      }
    } else if (param === PlayerInfoType.VIDEOS) {
      setVideoCount(videoCount + ALBUM_PAGE_AMOUNT);
    }
  }

  return (
    <>
      <div className={styles.tabBar}>
        <p
          className={classNames(styles.tab, {
            [styles.active]: activeTab === 0,
          })}
          onClick={() => setActiveTab(0)}
        >
          {t('coachPage.tabs.biography')}
        </p>
        {attributes?.player_career?.length ||
        attributes?.executive_career?.length ? (
          <p
            className={classNames(styles.tab, {
              [styles.active]: activeTab === 1,
            })}
            onClick={() => setActiveTab(1)}
          >
            {t('coachPage.tabs.career')}
          </p>
        ) : null}
        {attributes?.statistics?.length ? (
          <p
            className={classNames(styles.tab, {
              [styles.active]: activeTab === 2,
            })}
            onClick={() => setActiveTab(2)}
          >
            {t('coachPage.tabs.stats')}
          </p>
        ) : null}
        <p
          className={classNames(styles.tab, {
            [styles.active]: activeTab === 3,
          })}
          onClick={() => setActiveTab(3)}
        >
          {t('coachPage.tabs.photoAndVideo')}
        </p>
      </div>
      <div className={styles.article}>
        {activeTab === 0 && (
          <>
            {attributes?.biography?.length ? (
              <>
                <ShowMoreText
                  content={attributes.biography}
                  marginTop={30}
                  marginBottom={30}
                />
              </>
            ) : (
              <p>{t('coachPage.tabs.nothingHereYet')}</p>
            )}
          </>
        )}
        {activeTab === 1 && (
          <>
            {attributes?.player_career?.length ? (
              <>
                <p className={styles.title}>
                  {t('coachPage.tabs.playerCareer')}
                </p>
                <ShowMoreText
                  content={attributes.player_career}
                  marginTop={30}
                  marginBottom={30}
                />
              </>
            ) : null}
            {attributes?.executive_career?.length ? (
              <>
                <p className={styles.title}>
                  {t('coachPage.tabs.executiveCareer')}
                </p>
                <ShowMoreText
                  content={attributes.executive_career}
                  marginTop={30}
                  marginBottom={30}
                />
              </>
            ) : null}
          </>
        )}
        {activeTab === 2 && <ExecutiveStatisticTable executive={coach} />}
        {activeTab === 3 && (
          <div>
            <div className={styles.mediaTabs}>
              <button
                onClick={() => setActiveMediaTab(0)}
                className={classNames(styles.mediaTab, {
                  [styles.mediaTabActive]: activeMediaTab === 0,
                })}
              >
                {t('coachPage.tabs.photo')}
              </button>
              <button
                onClick={() => setActiveMediaTab(1)}
                className={classNames(styles.mediaTab, {
                  [styles.mediaTabActive]: activeMediaTab === 1,
                })}
              >
                {t('coachPage.tabs.video')}
              </button>
            </div>
            <div>
              {activeMediaTab === 0 ? (
                <>
                  {isPhotosLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className={styles.photosGrid}>
                        {images.length ? (
                          images.map((image, index) => (
                            <PlayerPhoto
                              key={`${image.id}-${index}`}
                              image={image}
                              openAlbum={openAlbum}
                              index={index}
                            />
                          ))
                        ) : (
                          <p>{t('coachPage.tabs.nothingHereYet')}</p>
                        )}
                      </div>
                      {isVisibleShowMoreBtn &&
                        activeMediaTab === 0 &&
                        images.length > 0 && (
                          <div className={styles.buttonWrapper}>
                            <ViewMoreButton
                              onClick={() => {
                                onLoadMore(PlayerInfoType.PHOTOS);
                              }}
                            />
                          </div>
                        )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {isLoadingStrapiVideos || isLoadingVkVideos ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className={styles.photosGrid}>
                        {videosToDisplay.length !== 0 ? (
                          videosToDisplay.map((video, index) => (
                            <PlayerVideo
                              video={video.videoData}
                              openVideo={onVideoSelect}
                              isVkVideo={video.isVkVideo}
                              index={index}
                              key={`${video.videoData.id}-${index}`}
                              defaultImage={defaultPhoto}
                            />
                          ))
                        ) : (
                          <div className={styles.noPhotos}>
                            {t('playerPage.tabs.nothingHereYet')}
                          </div>
                        )}
                      </div>
                      {videosToDisplay.length <= videoCount &&
                      strapiVideos.length + vkVideos.length >
                        videosToDisplay.length ? (
                        <div className={styles.buttonWrapper}>
                          <ViewMoreButton
                            onClick={() => {
                              onLoadMore(PlayerInfoType.VIDEOS);
                            }}
                          />
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <VideoModal
        isVisible={!!selectedVideo}
        videoSource={selectedVideo ? selectedVideo.source : ''}
        close={onClose}
        isVKVideo={selectedVideo?.isVkVideo}
      />
    </>
  );
}

export default CoachTabsWithInfo;
