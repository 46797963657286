import styles from './VideoPlayer.module.scss';
import { ReactElement, useEffect, useRef, useState } from 'react';

type VideoPlayerProps = {
  selectedVideo: string | undefined;
};

function VideoPlayer({ selectedVideo }: VideoPlayerProps): ReactElement {
  const playerWrapperRef = useRef<any>(null);
  const [minHeight, setMinHeight] = useState(0);

  const updateMinHeight = () => {
    if (playerWrapperRef.current) {
      const currentWidth = playerWrapperRef.current.offsetWidth;
      const calculatedMinHeight = currentWidth * 0.6;

      setMinHeight(calculatedMinHeight);
    }
  };

  useEffect(() => {
    updateMinHeight();

    window.addEventListener('resize', updateMinHeight);

    return () => {
      window.removeEventListener('resize', updateMinHeight);
    };
  }, []);

  if (!selectedVideo || selectedVideo === '') return <div></div>;

  return (
    <div
      className={styles.wrapper}
      ref={playerWrapperRef}
      style={{ minHeight: minHeight }}
    >
      <iframe
        title='VK video player'
        src={selectedVideo}
        allow='encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
        allowFullScreen
        style={{ width: '100%', height: '100%' }}
      ></iframe>
    </div>
  );
}

export default VideoPlayer;
